<template>
  <div>
    <DashboardMarket v-if="showMarket"/>
    <DashboardProducer v-if="showProducer"/>
    <DashboardIsland v-if="showIsland"/>
  </div>
</template>

<script>
import DashboardMarket from "./DashboardMarket.vue";
import DashboardProducer from "./DashboardProducer.vue";
import DashboardIsland from "./DashboardIsland.vue";

export default {
  data() {
    return {
    };
  },
  computed: {
    showMarket () {
      return this.$store.state.role.market__id > 0;
    },
    showProducer () {
      return this.$store.state.role.producer__id > 0;
    },
    showIsland () {
      return this.$store.state.role.island__id > 0;
    },
  },
  components: {
    DashboardMarket,
    DashboardProducer,
    DashboardIsland,
  },
};
</script>
