<template>
  <div>
    <h4>{{ $t("role.dashboard.market.title") }}</h4>
    <hr />
    <CRow>
      <CCol sm="12" lg="6">
        <DashboardMarketOrders />
      </CCol>
      <CCol sm="12" lg="3">
        <DashboardMarketPP />
      </CCol>
      <CCol sm="12" lg="3">
        <DashboardMarketPlan />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DashboardMarketOrders from "./DashboardMarketOrders.vue";
import DashboardMarketPP from "./DashboardMarketPP.vue";
import DashboardMarketPlan from "./DashboardMarketPlan.vue";

export default {
  data() {
    return {};
  },
  components: {
    DashboardMarketOrders,
    DashboardMarketPP,
    DashboardMarketPlan,
  },
};
</script>
