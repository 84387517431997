<template>
  <div>
    <h4>{{ $t("role.dashboard.producer.title") }}</h4>
    <hr />
    <CRow>
      <CCol sm="12" lg="6">
        <DashboardProducerOrders />
      </CCol>
      <CCol sm="12" lg="3">
        <DashboardProducerPP />
      </CCol>
      <CCol sm="12" lg="3">
        <DashboardProducerPlan />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DashboardProducerOrders from "./DashboardProducerOrders.vue";
import DashboardProducerPP from "./DashboardProducerPP.vue";
import DashboardProducerPlan from "./DashboardProducerPlan.vue";

export default {
  data() {
    return {};
  },
  components: {
    DashboardProducerOrders,
    DashboardProducerPP,
    DashboardProducerPlan,
  },
};
</script>
